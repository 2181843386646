import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons'
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "components/Layout"
import Seo from 'components/Seo'

const FieldSpecialistPage = () => {

  /**
   * Renders the main navigation area
   * @returns {JSX.Element}
   */
   const CTAButtons = () => {
    return(
      <nav className="grid grid-cols-1 mt-4 mb-6 text-center text-black border-y-2 md:gap-4 md:border-y-0 border-tecsight-blue">
        <OutboundLink 
          href="mailto:tfs@hutcheonandpearce.com.au"
          className="flex flex-col items-center justify-between p-8 font-bold bg-tecsight-green md:rounded-lg"
          >
          <FontAwesomeIcon 
            size="2x" 
            className="fill-current" 
            icon={faEnvelope} />
          <span className="pt-6">Email Support</span>
        </OutboundLink>
      </nav>
    );
  }

  return(
    <Layout>
      <Seo 
        title="Field Specialist"
        description="Have your field specialist visit your machine and help you with any issues you may have."
      />
      <h1 className="mb-2 text-3xl font-bold text-center text-tecsight-blue">Field Specialist</h1>
      <CTAButtons/>
      <article className="px-4 text-gray-600">
      <p className="mb-4">
        Our Field Services team optimisation visits ensure you make the 
        most of your machine and get real value from your data. Free with 
        the purchase of any 6 Series machine and above, within 30 days of 
        your machine start-up, we’ll arrange an on-farm visit to optimise 
        your machine and train you in the basics.
      </p>
      <p className="mb-4">
        While on your farm, our Field Services team identifies custom solutions 
        to meet your individual needs and coordinates delivery of these solutions, 
        whether they be equipment, parts, servicing or technology.
      </p>
      <p className="mb-4">
        Learn from the experts at customer clinics, demonstration and product 
        optimisation days. Our Field Services team stay across the latest in 
        Ag innovation, production practices, trends and any changes in regulations 
        and readily share that knowledge with you for better on-farm outcomes.
      </p>
      </article>
    </Layout>
  )
}

export default FieldSpecialistPage